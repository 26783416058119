<template>
	<div class="content" style="padding: 200px 0 0 0;">
		<div class="content_body">

			<div class="inner">
				<div class="apply_visual" style="width: 650px; height: 700px; text-align: center; margin: 0 auto;">

					<div class="content_head" >
						<div class="inner">
							<h2 class="page_title" style="text-align: center">로그인</h2>
						</div>
					</div>


					<div class="text_wrap">
						<strong class="tit" style="text-align: left">잠들어있는 <b>데이터를 분석</b>하여<br> <b>인사이트</b>를 얻으세요.</strong>
						<p class="desc" style="text-align: left">모든 서비스는 구글 계정을 통해서 진행되기에 아래 구글 로그인 버튼으로 로그인 부탁드립니다.</p>
					</div>
					<a href="#" id="signin_button" class="btn_apply">구글 로그인</a>

<!--					<div>-->
<!--						<div id="signin_button"></div>-->
<!--					</div>-->


				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "login",
	data() {
		return {
			reqUrl: ""
		}
	},
	created() {
		console.log(':::::::this.isLogin()', this.isLogin())

		// if (this.isLogin()) {
		// 	this.$router.push({ path: '/' })
		// }
	},
	mounted() {
		let googleScript = document.createElement('script');
		googleScript.src = 'https://accounts.google.com/gsi/client';
		document.head.appendChild(googleScript);

		window.addEventListener('load', () => {
			console.log(window.google);
			window.google.accounts.id.initialize({
				client_id: "1007128477735-9lrbddbo4jiu8jbtgdoghg66iaa0bg9h.apps.googleusercontent.com",
				callback: this.handleCredentialResponse,
				context: "signup",
				auto_select: "true",
				cancel_on_tap_outside: "true",
				ux_mode: "popup"

			});
			window.google.accounts.id.renderButton(
				document.getElementById("signin_button"),
					{theme: "filled_blue", type:'standard'}   // customization attributes
			);
			//window.google.accounts.id.prompt();
		})
	},
	methods: {

		handleCredentialResponse(response) {
			// decodeJwtResponse() is a custom function defined by you
			// to decode the credential response.
			//const responsePayload = decodeJwtResponse(response.credential);
			let responsePayload = response.credential.split('.')[1]; //value 0 -> header, 1 -> payload, 2 -> VERIFY SIGNATURE
			let payload = Buffer.from(responsePayload, 'base64');
			let result = JSON.parse(payload.toString())

			//유저가 존재하는지 체크
			this.findByUser(result);
		},

		findByUser(result) {
			const self = this;
			this.axiosCaller.post(this, this.APIs.USER + "/isUser", {email: result.email}, async (res) => {
				var data = res.data;
				if (data.status == "ok") {
					if (data.userVO != null) {
						// 로그인처리
						if (data.userVO.isUse === true) {

							// 게스트 여부
							data.userVO.isGuest = data.isGuest;

							// 게스트(초대받은이) 여부
							if(data.isGuest) {
								// 게스트 정보 세팅
								data.userVO.guestInfo = data.guestInfo;
							} else {
								// 일반 유저
								data.userVO.myService = data.myService;
							}

							if (await self.setSessionProc(data.userVO)) {

								self.setSessionKeep(self.isKeepSession);
								if (!this.stringUtils.isEmpty(this.$route.query.referrer)) {
									console.log(this.$route.query.referrer)
									location.href = this.$route.query.referrer
								} else {
									location.href = "/"
								}

							}


						} else {
							this.swalUtils.fire("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", null, null)
						}
					} else {
						// 회원가입으로 이동
						self.swalUtils.gritter("회원가입이 필요합니다", "", "info", 3000)
							.then(() => {
								location.href = "/member/signUp";
							});
					}
				} else {
					console.log(data.msg)
				}
			})
		},

	}
}
</script>

<style scoped>
.apply_visual .btn_apply {
	display: inline-block;
	padding: 12px 24px 12px 36px;
	margin-top: 40px;
	background: #1a73e8 url(/wapu/assets/img/common/ico_arrow_right_w.svg) no-repeat center left 16px/16px;
	border-radius: 2em;
	font-size: 14px;
	font-weight: 700;
	color: #FFF;
}

@media (max-width: 991px) {
	.apply_visual {
		width: 100% !important;
		margin: auto;
	}

}
@media (max-width: 767px) {
	.apply_visual {
		width: 100% !important;
		margin: auto;
	}
}


</style>